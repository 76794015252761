.global {
  box-sizing: border-box;
  padding: 119px 0 100px 184px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;

  .global__ellipse {
    position: absolute;
    width: 442.39px;
    height: 514.483px;
    transform: rotate(176.617deg);
    flex-shrink: 0;
    border-radius: 514.483px;
    background: rgba(255, 255, 255, 0.6);
    filter: blur(375px);
    left: -186px;
    top: 295px;
  }

  .global__title {
    // max-width: 474px;
    color: #fff;
    font-size: 48px;
    font-weight: 900;
    line-height: 130%;
    text-align: left;
    margin: 0;
  }

  .global__subtitle {
    margin: 20px 0 30px;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    background: var(
      --gradient-radial,
      radial-gradient(
        64.33% 64.33% at 71.16% 35.69%,
        #ecc5c5 0.89%,
        #edb7b7 17.23%,
        #e99999 42.04%,
        #e56f6f 55.12%,
        #e74141 71.54%,
        #e81515 100%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .global__paragraph {
    color: #868fa8;
    margin: 0;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 190.023%;
  }

  .global__image {
    max-width: 959px;
    width: 60%;
    margin: 0;
    padding: 0;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 1508px) {
  .global {
    padding: 119px 0 7% 100px;
    align-items: flex-start;
    .success__block-info {
      width: 100%;
  }
  .global__image {
      height: 100%;
    }
}
}

@media screen and (max-width: 1300px) {
  .global {
    padding: 119px 7% 100px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .success__block-info {
      width: 100%;
  }

  .global__title {
    text-align: center;
  }

  .global__subtitle {
    text-align: center;
  }

  .global__paragraph {
    text-align: center;
  }
  
  .global__image {
    width: 100%;
    }
}
}

@media screen and (max-width: 750px) {
  .global {
    padding: 70px 7%;
    .global__title {
      font-size: 24px;
    }

    .global__subtitle {
      font-size: 16px;
    }

    .global__paragraph {
      font-size: 12px;
    }
  }
}
