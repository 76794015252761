.footer {
  padding: 0 184px 64px;
  box-sizing: border-box;
  margin: 0;

  .footer__container {
    display: flex;
    justify-content: space-between;
    padding: 16px 0 36px;
    box-sizing: border-box;
    border-top: 3px solid rgba(255, 255, 255, 0.1);
  }

  .footer__title {
    margin: 0;
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
  }

  .footer__icons {
    display: flex;
    gap: 15px;
  }

  .footer__icon {
    width: 100%;
    height: 100%;
    max-height: 42px;
    object-fit: contain;
  }

  .footer__text {
    width: 100%;
    text-align: left;
    margin: 0;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 180%;
  }
}

@media screen and (max-width: 1508px) {
  .footer {
    padding: 0 7% 64px;

    .footer__container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .footer__title {
      text-align: center;
      margin-bottom: 20px;
    }

    .footer__icon {
      max-height: 42px;
    }

    .footer__text {
      text-align: center;
    }
  }
}
@media screen and (max-width: 750px) {
  .footer {
    .footer__container {
      padding: 16px 0 36px;
    }

    .footer__title {
      font-size: 12px;
    }

    .footer__icons {
      margin-top: 16px;
      flex-direction: column;
    }

    .footer__icon {
      width: 100%;
      height: 100%;
    }

    .footer__text {
      font-size: 10px;
    }
  }
}
