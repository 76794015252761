.payment {
  box-sizing: border-box;
  padding: 106px 184px 193px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .payment__ellipse {
    position: absolute;
    bottom: 300px;
    right: -140px;
    width: 436.52px;
    height: 544.066px;
    transform: rotate(47.457deg);
    flex-shrink: 0;
    border-radius: 200px;
    background: linear-gradient(90deg, #e98294 0%, #de132c 100%);
    filter: blur(325px);
  }

  .payment__title {
    margin: 0;
    max-width: 735px;
    color: #fff;
    text-align: center;
    font-size: 48px;
    font-weight: 900;
    line-height: 130%;
  }

  .payment__subtitle {
    max-width: 1151px;
    margin: 20px 0 70px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 180%;
    background: var(
      --gradient-radial,
      radial-gradient(
        64.33% 64.33% at 71.16% 35.69%,
        #ecc5c5 0.89%,
        #edb7b7 17.23%,
        #e99999 42.04%,
        #e56f6f 55.12%,
        #e74141 71.54%,
        #e81515 100%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .payment__box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 20px;
  }

  .payment__time-box {
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    width: 440px;
    height: 440px;
    border-radius: 1000px;
background: linear-gradient(154deg, #3b3a41 16.15%, #151420 93.23%);
box-sizing: border-box;
padding: 7%;
  }

  // .payment__image:nth-child(3) {
  //   grid-row: 1 / 3;
  //   grid-column: 2 / 3;
  // }
}

@media screen and (max-width: 1508px) {
  .payment {
    box-sizing: border-box;
    padding: 90px 7% 100px;

    .payment__box {
      width: 100%;
      // display: flex;
      // gap: 40px;
      // flex-direction: column;
    }

    .payment__image {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1300px) {
  .payment {
    .payment__box {
      display: flex;
      gap: 40px;
      flex-direction: column;
    }

    .payment__time-box {
      width: 100%;
      height: auto;
  padding: 7%;
    }
  }

}

@media screen and (max-width: 750px) {
  .payment {
    padding: 70px 7%;
    .payment__title {
      font-size: 24px;
    }

    .payment__subtitle {
      margin: 20px 0 30px;
      font-size: 14px;
    }

    .payment__box {
      gap: 30px;
    }

    .payment__time-box {
      width: 100%;
      height: 100%;
    }
  }
}
