.potential {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 100px 184px 120px;
  box-sizing: border-box;

  .potential__title {
    width: 735px;
    color: #00040f;
    text-align: center;
    font-size: 48px;
    font-weight: 900;
    line-height: 130%;
    margin: 0;
  }

  .potential__subtitle {
    max-width: 400px;
    color: #e81515;
    margin: 20px 0 50px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
  }

  .potential__cards-end {
    display: flex;
    gap: 20px;
    flex-direction: row;
    margin-top: 20px;
    align-items: center;
  }
}

.potential__cards {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.card:nth-child(1) {
  grid-area: 1 / 1 / 3 / 3;
}
.card:nth-child(2) {
  grid-area: 1 / 3 / 3 / 5;
}
.card:nth-child(3) {
  grid-area: 1 / 5 / 3 / 7;
}
.card:nth-child(4) {
  grid-area: 3 / 1 / 4 / 4;
}
.card:nth-child(5) {
  grid-area: 3 / 4 / 4 / 7;
}

@media screen and (max-width: 1508px) {
  .potential {
    padding: 100px 7% 120px;
  }
}

@media screen and (max-width: 1300px) {
  .potential {
        .potential__title {
      max-width: 735px;
    }

    .potential__cards {
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(3, auto);
      grid-gap: 20px;
    }

    .card:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }
    .card:nth-child(2) {
      grid-area: 1 / 2 / 2 / 3;
    }
    .card:nth-child(3) {
      grid-area: 2 / 1 / 3 / 2;
    }
    .card:nth-child(4) {
      grid-area: 2 / 2 / 3 / 3;
    }
    .card:nth-child(5) {
      grid-area: 3 / 1 / 4 / 3;
    }
  }
}

@media screen and (max-width: 750px) {
  .potential {
    padding: 70px 7%;

    .potential__title {
      font-size: 24px;
    }

    .potential__subtitle {
      font-size: 18px;
    }

    .potential__cards {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
    }
  }
}
