.card {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(54, 187, 208, 0.2);
  display: flex;
  align-items: center;

  .card__image {
    width: 100%;
    height: 100%;
    max-width: 290px;
    max-height: 247px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .card__box-end {
max-width: 328px;
  }

  .card__description {
    color: #e81515;
    font-size: 14px;
    font-weight: 700;
    line-height: 130%;
    margin: 0;
  }

  .card__title {
    color: #00040f;
    font-size: 20px;
    font-weight: 900;
    line-height: 130%;
    margin: 4px auto 9px;
    max-width: 227px;
  }

  .card__text {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    margin: 0;
  }
}

.card-start {
  padding: 0px 66px 76px;
  text-align: center;

  flex-direction: column;

  justify-content: space-evenly;
}

.card-end {
  max-width: 670px;
  text-align: left;
  color: #e81515;
  flex-direction: row-reverse; 

  padding: 30px 25px 27px 40px;
  box-sizing: border-box;

  justify-content: space-between;

  .card__title {
    margin: 4px 0 9px;
  }
}

@media screen and (max-width: 1300px) {
  .card {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .card__image {
      max-width: 220px;
    }
  }

  .card-start {
    max-width: 307px;
    padding: 0px 30px 50px;
  }

  .card-end {
    text-align: center;
    flex-direction: column;
  }

  .card:nth-child(4) {
    max-width: 307px;
    padding: 0px 30px 50px;
    justify-content: space-evenly;
  }

  .card:nth-child(5) {
    justify-content: space-between;
    flex-direction: row-reverse;
    max-width: 634px;
    text-align: left;
    max-height: 260px;
    .card__title {
      margin: 4px 0 9px;
    }
  }
}

@media screen and (max-width: 750px) {
  .card {
    max-width: 307px;
    width: 100%;
    height: 438px;
    padding: 0px 30px 50px;
    justify-content: space-evenly;
    flex-direction: column;

    .card__description {
      font-size: 12px;
    }

    .card__title {
      font-size: 16px;
    }

    .card__text {
      font-size: 12px;
    }

    .card-end {
      text-align: center;
      flex-direction: column;
    }
  }

  .card:nth-child(5) {
    max-width: 307px;
    padding: 0px 30px 50px;
    justify-content: space-evenly;
    flex-direction: column;
    text-align: center;
    max-height: 538px;
    .card__title {
      margin: 4px auto 9px;
    }
  }
}
