.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  overflow: hidden;

  .slider__ellipse {
    position: absolute;
    left: -297px;
    bottom: 1410px;
    width: 345px;
    height: 514px;
    transform: rotate(156.607deg);
    flex-shrink: 0;
    border-radius: 200px;
    background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
    filter: blur(450px);
  }
  .slider__title {
    margin: 0;
    max-width: 804px;
    color: #fff;
    font-size: 48px;
    font-weight: 900;
    line-height: 130%;
  }

  .slider__subtitle {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    background: var(
      --gradient-new-radial,
      radial-gradient(
        3360.72% 61.45% at 74.26% 46.55%,
        #e49797 0.89%,
        #e97878 17.23%,
        #eb7171 42.04%,
        #e56f6f 55.12%,
        #e74141 71.54%,
        #e81515 100%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 20px 0 30px;
  }

  .slider__paragraph {
    max-width: 804px;
    color: #868fa8;
    font-size: 18px;
    font-weight: 500;
    line-height: 190%;
    margin: 0;
  }

  .slider__box {
    width: 100%;
    box-sizing: border-box;
    padding: 67px 0 71px 184px;
    overflow: hidden;
  }
}

.slider__box .swiper-slide img {
  max-width: 350px;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.swiper-slide {
  max-width: 350px;
  width: 100%;
  height: auto;
  object-fit: contain;
}

@media screen and (max-width: 1508px) {
  .slider {
    .slider__block-info {
      box-sizing: border-box;
      padding: 0 7%;
    }

    .slider__box {
      padding: 67px 0 71px 7%;
    }
  }
}
@media screen and (max-width: 750px) {
  .slider {
    .slider__title {
      font-size: 24px;
    }

    .slider__subtitle {
      font-size: 16px;
      max-width: 250px;
      margin: 20px auto 30px;
    }

    .slider__paragraph {
      font-size: 12px;
    }
  }
}
