.breeze {
  padding: 100px 184px 25px;
  box-sizing: border-box;
  background-color: #00040f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .breeze__ellipse {
    position: absolute;
    width: 640px;
    height: 560px;
    border-radius: 640px;
    background: rgba(255, 255, 255, 0.6);
    filter: blur(375px);
    left: 544px;
    top: 29px;
  }

  .breeze__title {
    margin: 0;
    max-width: 735px;
    color: #fff;
    text-align: center;
    font-size: 48px;
    font-weight: 900;
    line-height: 130%;
  }

  .breeze__subtitle {
    margin: 20px 0 23px;
    max-width: 760px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 180%;
    background: var(
      --gradient-radial,
      radial-gradient(
        64.33% 64.33% at 71.16% 35.69%,
        #ecc5c5 0.89%,
        #edb7b7 17.23%,
        #e99999 42.04%,
        #e56f6f 55.12%,
        #e74141 71.54%,
        #e81515 100%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .breeze__paragraph {
    margin: 0 0 71px;
    max-width: 591px;
    color: #868fa8;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 190%;
  }

  .breeze__block-info {
    margin-top: 58px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;

    .breeze__logo {
      max-width: 291.667px;
      max-height: 70px;
      flex-shrink: 0;
      margin-bottom: 7px;
    }

    .breeze__list {
      display: flex;
      .breeze__link {
        cursor: pointer;
        text-decoration: none;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;
        margin: 0 0 0 26px;

        .breeze__text-link {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1508px){
  .breeze {
    padding: 100px 7% 25px;
  
    .breeze__title {
      font-size: 48px;
      // max-width: 500px;
    }
  
    .breeze__subtitle {
      margin: 20px 0 23px;
      font-size: 24px;
    }
  
    .breeze__paragraph {
      margin: 0 0 71px;
      font-size: 18px;
    }
  
    
  
  }
}

@media screen and (max-width: 750px){
  .breeze {
    padding: 70px 7% 25px;
  
    .breeze__title {
      font-size: 24px;
    }
  
    .breeze__subtitle {
      font-size: 16px;
    }
  
    .breeze__paragraph {
      font-size: 14px;
    }
  
    .breeze__block-info {
      margin-top: 58px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      align-items: flex-end;
  
      .breeze__logo {
        width: 100%;
        height: 100%;
        margin: 0 auto 25px;
      }
  
      .breeze__list {
        margin: auto;
        .breeze__link {
          font-size: 12px;
          margin: 0 26px 0 0;
        }
      }
    }
}
}
