.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.header__content {
  box-sizing: border-box;
  padding: 20px 184px 129px;
}

.header {
  width: 100%;
  background-image: url("../../images/header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: 0px -260px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .header__container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;

    .header__logo {
      max-width: 332.263px;
      max-height: 80px;
      flex-shrink: 0;
    }

    .header__container-link {
      display: inline-flex;
      box-sizing: border-box;
      padding: 10px 18px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 2px solid #fff;
      cursor: pointer;

      .header__link {
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        text-decoration: none;
      }
      .header__icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .header__title {
    margin: 79px 0 40px;
    max-width: 561px;
    color: #fff;
    font-size: 52px;
    font-weight: 700;
    line-height: 110%;
    text-align: left;

    .header__title-span {
      background: var(
        --gradient-radial,
        radial-gradient(
          64.33% 64.33% at 71.16% 35.69%,
          #ecc5c5 0.89%,
          #edb7b7 17.23%,
          #e99999 42.04%,
          #e56f6f 55.12%,
          #e74141 71.54%,
          #e81515 100%
        )
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .header__image-container {
    max-width: 311px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 19px;
    grid-row-gap: 20px;
    margin-bottom: 64px;
  }

  .header__icon {
    width: 64px;
    height: 43px;
  }

  .header__icon:nth-child(5) {
    grid-column: 1 / 5;
    width: 311px;
    height: 41px;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 1508px) {
  .header__content {
    padding: 20px 7% 129px;
  }

  .header {
    background-position: 0 0;
      .header__logo {
        width: 332.263px;
        height: 80px;
        flex-shrink: 0;
      }

      .header__title {
        margin: 79px 0 40px;
        max-width: 561px;
        color: #fff;
        font-size: 52px;
        font-weight: 700;
        line-height: 110%;
        text-align: left;

        .header__title {
          margin: 50px 0 40px;
        }
      }
    }
  }


@media screen and (max-width: 900px) {
  .header__content {
    padding: 20px 7% 129px;
  }

  .header {
    background-position: 0 0;

    .header__container {
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;

      .header__logo {
        width: 332.263px;
        height: 80px;
        flex-shrink: 0;
        margin-bottom: 20px;
      }

      .header__title {
        margin: 79px 0 40px;
        max-width: 561px;
        color: #fff;
        font-size: 52px;
        font-weight: 700;
        line-height: 110%;
        text-align: left;

        .header__title {
          margin: 50px 0 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 637px) {
  .header {
    .header__container {
      .header__logo {
        width: 100%;
        height: 100%;
      }

      .header__container-link {
        padding: 7px 14px;

        .header__link {
          font-size: 12px;
        }
        .header__icon {
          width: 14px;
          height: 14px;
        }
      }
    }

    .header__title {
      margin: 40px 0;
      font-size: 26px;
      max-width: 330px;
    }

    .header__image-container {
      width: 100%;
      height: 100%;
      max-width: 311px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 20px;
      margin-bottom: 64px;
    }

    .header__icon {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .header__icon:nth-child(5) {
      grid-column: 1 / 5;
      width: 100%;
      height: 100%;
      object-fit: contain;
      flex-shrink: 0;
    }
  }
}
