.button {
  z-index: 3;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  border-radius: 10px;
  border: none;
  border-radius: 10px;
  background: linear-gradient(180deg, #EBC9CD 0.89%, #EEB3BB 17.23%, #EB8B96 42.04%, #EB5C6D 55.12%, #E53D51 71.54%, #E8162F 100%);
  display: flex;
  box-sizing: border-box;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;

  .button__link {
    color: #fff;
    text-decoration: none;
  }
}

@media screen and (max-width: 750px){
.button {
  font-size: 16px;
  padding: 8px 12px;
 }
}