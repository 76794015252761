@font-face {
  font-family: "Inter";
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("../fonts/Inter-Black.woff2") format("woff2");
  src: url("../fonts/Inter-Black.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/Inter-Bold.woff2") format("woff2");
  src: url("../fonts/Inter-Bold.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("../fonts/Inter-Medium.woff2") format("woff2");
  src: url("../fonts/Inter-Medium.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/Inter-Regular.woff2") format("woff2");
  src: url("../fonts/Inter-Regular.woff") format("woff");
}