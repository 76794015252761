.App {
  max-width: 1728px;
  min-width: 320px;
  text-align: center;
  min-height: 100vh;
  margin: 0 auto;
  overflow: hidden;

  .content {
    background: #00040F;
    position: relative;
  }

  .info {
    box-sizing: border-box;
    padding: 40px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 190.023%;

    .info__title {
font-size: 30px;
font-weight: 700;
line-height: normal;
    }

    ul {
      padding: 0;
      margin: 0;
    }
  }
}
