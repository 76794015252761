.success {
  box-sizing: border-box;
  padding: 95px 184px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 67px;

  .success__image {
    max-width: 571px;
    height: 571px;
  }

  .success__block-info {
    width: 80%;
    .success__title {
      margin: 0;
      text-align: left;
      max-width: 100%;
      max-width: 621px;
      color: #00040f;
      font-size: 48px;
      font-weight: 900;
      line-height: 130%;
    }

    .success__paragraph {
      margin: 30px 0 37px;
      text-align: left;
      color: #00040f;
      font-size: 18px;
      font-weight: 500;
      line-height: 190.023%;
    }
  }
}

@media screen and (max-width: 1508px) {
  .success {
    padding: 95px 7%;
    gap: 30px;

    .success__image {
      width: 100%;
      height: 100%;
    }

    .success__block-info {
      width: 90%;
    }
  }
}

@media screen and (max-width: 1160px) { 
  .success {
    display: flex;
    flex-direction: column;

  .success__block-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    .success__title {
      text-align: center;
      width: 100%;
    }

    .success__paragraph {
      text-align: center;
    }
  }
}
}

@media screen and (max-width: 750px) {
  .success {
    padding: 70px 7%;
    .success__block-info {
      .success__title {
        font-size: 24px;
      }
      .success__paragraph {
        font-size: 12px;
      }
    }
  }
}
